import React from 'react';
import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, Modal, Dimensions, ScrollView, SafeAreaView, FlatList } from 'react-native';
import { useState, useEffect, useCallback, useContext } from 'react';
import { server_var, themeColor } from '../config/servervar';
import { store } from "../sharewebapp/store";
import { CustomIcon } from '../component/custom';
import moment from 'moment';

export function GrabStatusPart({ navigation, route }) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);

  const { grabId, ordermainId } = route?.params

  useEffect(() => {
    loadDataList(0);
  }, [grabId])

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      const res = await globalState.client.service('apilogs').find({
        query: {
          type: 'grabOrder',
          $skip: pSkip ?? skip,
          $sort: { id: -1 },
          $or: [
            // { altId1: grabId },
            { altId1: { $in: grabId.split(',') } },
            { altId2: ordermainId }
          ]
        }
      });
      if ((pSkip ?? skip) === 0) {
        setDataList(res.data);
      } else {
        setDataList(prev => [...prev, ...res.data]);
      }
      setSkip((res.skip + res.limit));
      setLoading(false);
      console.log(res.data);
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
      <Text style={{ textAlign: 'center', marginTop: 10 }}>Grab Ref Id: {grabId} : {ordermainId}</Text>
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        data={dataList}
        onEndReached={(xxx) => loadDataList()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}
        style={{ marginTop: 10 }}
        renderItem={({ item }) => (
          <GrabItem item={item} />
        )}
      />
    </SafeAreaView>
  )
}

function GrabItem({ item }) {
  const [expandFlag, setExpandFlag] = useState(false);
  const tmpTextStyle = item?.altId2 ? { color: 'gray' } : { color: 'black' }
  const GRAB_STATUS_LIST = [
    { statusName: 'COMPLETED', text: 'จัดส่งสินค้าสำเร็จ' },
    { statusName: 'IN_DELIVERY', text: 'กำลังจัดส่ง' },
    { statusName: 'PENDING_DROP_OFF', text: 'รอการส่งสินค้า' },
    { statusName: 'PICKING_UP', text: 'กำลังรับสินค้า' },
    { statusName: 'PENDING_PICKUP', text: 'รอการเข้ารับสินค้า' },
    { statusName: 'ALLOCATING', text: 'กำลังหา rider' },
    { statusName: 'FAILED', text: 'การส่งไม่สำเร็จ' },
    { statusName: 'CANCELED', text: 'ยกเลิก' },
  ]
  const translateGrabStatus = (GRAB_STATUS_LIST.find(item2 => item2.statusName == (item.data?.status))?.text) ?? (item.data?.status)

  return (
    <View>
      <View style={{ flexDirection: 'row', marginTop: 10, marginHorizontal: 20, borderBottomWidth: 1, borderColor: 'lightgray', paddingBottom: 10 }}>
        <View style={{ flex: 0.9 }}>
          <Text style={tmpTextStyle}>หมายเลขรับงานขนส่ง: {item?.altId1}</Text>
          <Text style={tmpTextStyle}>สถานะ: <Text style={{ color: themeColor.color1 }}>{translateGrabStatus}</Text></Text>
          <Text style={tmpTextStyle}>เวลาที่แจ้ง: <Text style={{ color: 'gray', fontSize: 12 }}>{item?.updatedAt}</Text></Text>
          {expandFlag && 
          <View style={{ flexDirection:'row' }}>
            <Text style={{ flex: 1, flexGrow: 1, width: 0, color: 'gray', fontSize: 12 }}>{JSON.stringify(item?.data, '', 2)}</Text>
          </View>
          }
        </View>
        <View style={{ flex: 0.1 }}>
          <TouchableOpacity onPress={() => setExpandFlag(prev => !prev)}>
            <CustomIcon name={expandFlag ? "chevron-up-circle" : "chevron-down-circle"} size={20} color={themeColor.color1} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

