import { useState, useEffect, useRef } from 'react';
import { server_var } from "../config/servervar";
import { Platform, AppState } from "react-native";
import { getDeviceId, removeJwtFromAsyncStore, getJwtToken, isJailbreak, isWrongCert } from "../component/custom";
import moment from 'moment';

export function useCommon(globalState, dispatch) {
  const appState = useRef(AppState.currentState);

  useEffect(() => {
    appReLogin(globalState, dispatch);
  }, []);

  useEffect(() => {
    globalState.client.io.on('connect', () => {
      dispatch({ type: 'setOnlineStatus', payload: 'online' });
    })
    globalState.client.io.on('disconnect', () => {
      dispatch({ type: 'setOnlineStatus', payload: 'offline' });
    })
  }, [])

  useEffect(() => {
    globalState.client.service('notitemplogs').on('created', onSocketListner);
    return () => {
      globalState.client.service('notitemplogs').removeListener('created', onSocketListner);
    };
  }, [globalState.user]);

  useEffect(() => {
    if (globalState?.user?.id) {
      loadInit()
    }
  }, [globalState.user])

  useEffect(() => {
    if (globalState?.user?.id) {
      const subscription = AppState.addEventListener("change", nextAppState => {
        if (
          appState.current.match(/inactive|background/) && nextAppState === "active"
        ) {
          loadInit();
        }
        appState.current = nextAppState;
      });

      return () => {
        subscription.remove();
      };
    }
  }, [globalState.user])

  const loadInit = async () => {
    await loadNoti();
    await loadBasket();
    await loadAlert();
  }

  const loadAlert = async () => {
    const query = {
      query: {
        type: { $in: ['PromoAlert', 'Alert'] },
        source: 1,
        $limit: 50,
        toUserId: globalState?.user?.id,
        $sort: { id: -1 }
      }
    };
    console.log('query', query);
    const res = await globalState.client.service('notilogs').find(query);
    console.log('promo alert' + JSON.stringify(res, '', 2));
    if (res.total > 0) {
      for (const item of res.data) {
        dispatch({ type: 'addNotiAlertData', payload: item });
      }
      dispatch({ type: 'showNotiAlertView' })
    }
  }

  const loadNoti = async () => {
    const jwtToken = await getJwtToken();
    const res = await fetch(`${server_var.server_api}/loadNoti`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
      body: JSON.stringify({ userId: globalState.user.id })
    });
    const resJson = await res.json();
    const resJson2 = Object.entries(resJson);
    for (const item of resJson2) {
      if (['MsgTotalUnreadU2U', 'MsgTotalUnreadS2U', 'MsgTotalUnreadU2S', 'QuelineTotalUnread', 'UnreadNoti'].includes(item[0])) {
        dispatch({ type: `set${item[0]}`, payload: parseInt(item[1]) });
      }
    }
  }

  const loadBasket = async () => {
    const res = await globalState.client.service('baskets').find({ query: { userId: globalState.user.id } });
    dispatch({ type: 'setBasketAmount', payload: res.total })
  }

  const onSocketListner = (payload) => {
    if (payload.toUserId == globalState?.user?.id) {
      if (['MsgTotalUnreadU2U', 'MsgTotalUnreadS2U', 'MsgTotalUnreadU2S', 'QuelineTotalUnread'].includes(payload.type)) {
        dispatch({ type: `set${payload.type}`, payload: parseInt(payload?.data?.unread) });
      }
      if (payload.type == 'RefreshBasket' && payload?.data?.userId == globalState?.user?.id) {
        dispatch({ type: 'setBasketAmount', payload: payload?.data?.basketCount })
      }
      if (payload.type == 'RefreshNoti') {
        dispatch({ type: 'setUnreadNoti', payload: payload?.data?.unreadNoti })
      }
      if (payload.type == 'ShowAlert') {
        dispatch({ type: 'addNotiAlertData', payload: payload?.data?.alertData })
        dispatch({ type: 'showNotiAlertView' })
      }
      if (payload.type == 'ForwardToShop') {
        dispatch({ type: 'setForwardInfo', payload: { timeStamp: Math.floor(Date.now() / 1000), shopId: payload?.data?.shopId } })
      }
    }
  };

  return null;
}

export async function appLogin(mobileNumber, password, globalState, dispatch) {
  if (await isJailbreak() && Platform.OS != 'web') {
    return Promise.reject('ขออภัยค่ะ ไม่สามารถใช้งานแอพ ALL Pharma See กับเครื่องนี้ได้ (401)');
  }
  if (await isWrongCert() && Platform.OS != 'web') {
    return Promise.reject('ขออภัยค่ะ ไม่สามารถใช้งานแอพ ALL Pharma See กับเครื่องนี้ได้ (402)');
  }

  let res1;
  try {
    res1 = await globalState.client.authenticate({
      strategy: "local",
      mobileNumber: mobileNumber,
      password: password,
    });
    let res2;
    try {
      res2 = await globalState.client.service('users').get(res1?.user?.id);
    } catch (e) {
      console.log('error login ', res2);
    }
    console.log('login ', JSON.stringify(res2));
    if (res2?.staffFlag == 1) {
      if (res2?.staffStatus != 'A') {
        return Promise.reject("บัญชีนี้ไม่สามารถใช้งานได้");
      }
    }

    dispatch({ type: 'setUser', payload: res2 });
    dispatch({ type: 'setLastPinTime', payload: Date.now() });
    return Promise.resolve(res1);
  } catch (e) {
    return Promise.reject("Pin หรือรหัสผ่านไม่ถูกค้อง");
  }
}

export async function appReLogin(globalState, dispatch) {
  if (await isJailbreak() && Platform.OS != 'web') {
    alert('ขออภัยค่ะ ไม่สามารถใช้งานแอพ ALL Pharma See กับเครื่องนี้ได้ (401)')
    return;
  }
  if (await isWrongCert() && Platform.OS != 'web') {
    alert('ขออภัยค่ะ ไม่สามารถใช้งานแอพ ALL Pharma See กับเครื่องนี้ได้ (402)')
    return;
  }
  let res;
  try {
    res = await globalState.client.reAuthenticate();
    let res2;
    try {
      res2 = await globalState.client.service('users').get(res?.user?.id);
      dispatch({ type: 'setUser', payload: res2 });
    } catch (e) {
      console.log('error login', res2);
    }
  } catch (err) {
    console.log('relogin error' + JSON.stringify(err));
    if (err?.message?.includes('No record found for id') && Platform.OS != 'web') {
      await removeJwtFromAsyncStore();
      globalState.socket.disconnect();
      globalState.socket.connect(server_var.server_api);
    }
  }

  try {
    const res2 = await fetch(`${server_var.server_api}/init`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    });
    const resJson2 = await res2.json();
    dispatch({ type: 'setInitInfo', payload: resJson2 })
  } catch (err) {
    console.log('init info error' + JSON.stringify(err));
  }
};

export async function appLogout(globalState, dispatch) {
  if (Platform.OS != 'web') {
    const deviceId = await getDeviceId();
    globalState.client.service('phonelogs').patch(null, { active: 0 }, {
      query: { userId: globalState?.user?.id, deviceId, active: 1 }
    });
  }
  await globalState.client.logout();
  dispatch({ type: 'setUser', payload: null });
  dispatch({ type: 'zeroAllNoti', payload: null });
}

export const HEADER_BAR_LIST_FOR_SHOP = (onPress) => {
  return [
    {
      id: 1, name: 'basket', iconName: 'basket', redText: 'basket', onPress: () =>
        onPress('basket')
    },
    {
      id: 2, name: 'editpreorder', iconName: 'note-text-outline', onPress: () =>
        onPress('editpreorder')
    },    
    {
      id: 3, name: 'viewuser', iconName: 'account-circle', onPress: () =>
        onPress('viewuser')
    },
    {
      id: 4, name: 'call', iconName: 'phone', onPress: () =>
        onPress('call')
    },
    {
      id: 5, name: 'close', iconName: 'exit-to-app', onPress: () =>
        onPress('close')
    },

  ];
}

export function numberWithCommas(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return x;
  }
  //return x.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);
  return debouncedValue;
}

export function formatPhoneNumber(phoneNumberString) {
  const match = phoneNumberString.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (match?.[1] ?? '') + '-' + (match?.[2] ?? '') + '-' + (match?.[3] ?? '');
}

export function getOpenCloseTimeList(jsonData) {
  const SHOP_DESC_LIST = Object.entries({
    mon: { title: 'จันทร์' },
    tue: { title: 'อังคาร' },
    wed: { title: 'พุธ' },
    thu: { title: 'พฤหัส' },
    fri: { title: 'ศุกร์' },
    sat: { title: 'เสาร์' },
    sun: { title: 'อาทิตย์' },
  });
  const temp = SHOP_DESC_LIST.map(item => {
    return {
      title: item[1].title,
      start: jsonData?.[item[0]]?.start,
      end: jsonData?.[item[0]]?.end,
      enable: jsonData?.[item[0]]?.enable
    }
  });
  return temp.filter(item => item.enable);
}
export function getTotalPriceText(orderMain) {
  const totalPrice = numberWithCommas(
    ([
      orderMain?.orderPrice,
      orderMain?.servicePrice,
      orderMain?.servicePharmacistPrice,
      orderMain?.deliveryPrice,
      parseFloat(orderMain?.discountPrice ?? 0) * -1,
    ].reduce((acc, item3) => acc + parseFloat(item3), 0)).toFixed(2));
  return totalPrice;
}
export const GENDER_LIST = [
  { id: 1, value: 'F', title: 'หญิง', },
  { id: 2, value: 'M', title: 'ชาย', },
  { id: 3, value: 'U', title: 'ไม่ระบุ', },
]

export const ORDER_STATUS = [
  { id: 1, value: 'in_basket', title: 'สินค้าอยู่ในตระกร้า' },
  { id: 2, value: 'customer_paid', title: 'ลูกค้าชำระเงินแล้ว' },
  { id: 3, value: 'exta_start_deliver', title: 'ร้านเริ่มจัดส่ง' },
  { id: 4, value: 'partner_start_deliver', title: 'Lalamove เริ่มจัดส่ง' },
  { id: 5, value: 'partner_complete_deliver', title: 'Lalamove ส่งเรียบร้อยแล้ว' },
  { id: 6, value: 'exta_complete_deliver', title: 'ร้านยาส่งเรียบร้อยแล้ว' },
  { id: 7, value: 'customer_got_deliver', title: 'ลูกค้าได้รับสินค้าแล้ว' },
  { id: 8, value: 'cancel', title: 'ยกเลิกคำสั่งซื้อ', title2: 'ยกเลิกคำสั่งซื้อ (Post Void)' },
  { id: 9, value: 'cancel_by_system', title: 'ยกเลิกคำสั่งซื้อ', title2: 'ยกเลิกเนื่องจากตระกร้าสินค้าหมดอายุ' },
  { id: 10, value: 'order_problem', title: 'คำสั่งซื้อมีปัญหา' },
  { id: 11, value: 'self_pickup_start', title: 'รอลูกค้ารับสินค้า' },
  { id: 12, value: 'self_pickup_complete', title: 'ลูกค้ารับสินค้าแล้ว' },
  { id: 13, value: '7deli_start_deliver', title: '7 Delivery เริ่มจัดส่ง' },
  { id: 14, value: 'speedd_start_deliver', title: 'Speed D เริ่มจัดส่ง' },
  { id: 15, value: 'post_start_deliver', title: 'ไปรษณีย์ เริ่มจัดส่ง' },
  { id: 16, value: '7deli_complete_deliver', title: '7 Delivery จัดส่งแล้ว' },
  { id: 17, value: 'speedd_complete_deliver', title: 'Speed D จัดส่งแล้ว' },
  { id: 18, value: 'post_complete_deliver', title: 'ไปรษณีย์ จัดส่งแล้ว' },
  { id: 19, value: 'cancel_by_admin', title: 'ยกเลิกคำสั่งซื้อ', title2: 'ยกเลิกโดยแอดมิน' },
  { id: 20, value: 'selfpickup_not_pickup', title: 'ไม่รับสินค้าตามกำหนด' },
  { id: 21, value: 'partner_reject', title: 'ไรเดอร์ปฏิเสธงาน' },
  { id: 22, value: 'grab_start_deliver', title: 'Grab เริ่มจัดส่ง' },
  { id: 23, value: 'grab_complete_deliver', title: 'Grab ส่งเรียบร้อยแล้ว' },
  { id: 24, value: 'grab_reject', title: 'ไรเดอร์ Grab ปฏิเสธงาน' },
]

export const PHARMANOTE_STATUS = [
  { id: 1, value: 'done', title: 'สำเร็จ' },
  { id: 2, value: 'cancel_by_system', title: 'ยกเลิกโดยระบบ' },
  { id: 3, value: 'cancel_by_user', title: 'ยกเลิกโดยลูกค้า' },
]

export const DELIVER_LIST = [
  { value: 'self_pickup', title: 'รับสินค้าที่ร้าน' },
  { value: 'speed_d', title: 'จัดส่งวันถัดไปโดย SpeedD' },
  { value: '7deli', title: 'จัดส่งโดย 7 Delivery' },
  { value: 'lalamove', title: 'จัดส่งโดย Lalamove' },
  { value: 'post', title: 'จัดส่งโดย ไปรษณีย์' },
  { value: 'grab', title: 'จัดส่งโดย Grab' },
]

export const PAYMENT_LIST = [
  { value: 'prompt_pay', title: 'Prompt Pay' },
  { value: 'credit_card', title: 'บัตรเครดิต/เดบิต' },
  // { value: 'creditcard', title: 'บัตรเครดิต/เดบิต' },
  { value: 'true_money_wallet', title: 'ทรูมันนี่' },
  { value: 'test_payment', title: 'ทดสอบ' },
]

export function truncateString(str, num) {
  if (!str) {
    return '';
  }
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

export const ZONE_LIST = [
  { id: 1, value: 'ALL', title: 'ทั้งหมด' },
  { id: 2, value: 'BE', title: 'BE' },
  { id: 3, value: 'BG', title: 'BG' },
  { id: 4, value: 'BN', title: 'BN' },
  { id: 5, value: 'BS', title: 'BS' },
  { id: 6, value: 'BW', title: 'BW' },
  { id: 7, value: 'CM', title: 'CM' },
  { id: 8, value: 'NEL', title: 'NEL' },
  { id: 9, value: 'NEU', title: 'NEU' },
  { id: 10, value: 'RC', title: 'RC' },
  { id: 11, value: 'REL', title: 'REL' },
  { id: 12, value: 'REU', title: 'REU' },
  { id: 13, value: 'RN', title: 'RN' },
  { id: 14, value: 'RSL', title: 'RSL' },
  { id: 15, value: 'RSU', title: 'RSU' },
]

export const SHOP_STATUS = [
  { id: 1, value: 'ALL', title: 'ทั้งหมด' },
  { id: 2, value: 'online', title: 'Online' },
  { id: 3, value: 'offline', title: 'Offline' },
]

export const PRE_ORDER_STATUS = [
  { id: 1, value: 'pre_ordered', title: 'สร้างรายการสั่งจองสินค้าสำเร็จ' },
  { id: 2, value: 'customer_got_deliver', title: 'รับสินค้าสำเร็จ' },
  { id: 3, value: 'cancel', title: 'ยกเลิก' }
]

export const ORDER_VOID_STATUS = [
  { id: 1, value: 'void_request', title: 'ส่งคำขอยกเลิก' },
  { id: 2, value: 'void_process', title: 'อยู่ระหว่างดำเนินการยกเลิก' },
  { id: 3, value: 'void_complete', title: 'ยกเลิกคำสั่งซื้อ' }
  //{ id: 4, value: 'void_reject', title: 'ไม่อนุมัติคำขอยกเลิก' }, //have to set status back to NULL
]
